// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import "@hotwired/turbo-rails"

$(document).on("turbo:load", () => {
  $("#bouncing-loader").addClass("hidden")
})

Turbo.start()

import lozad from "lozad"

// initializing Lozad for lazy loading images using the native IntersectionObserver api (when available)
const observer = lozad() // lazy loads elements with default selector as '.lozad'
observer.observe()

import "@/lib/vue/plugins"

import "@/packs/libs/font-awesome/index.js"
import "@/lib/vue/directives"

import "../frontend/cart-counter.js"
import "../frontend/mobile-favourites-counter.js"
import "../frontend/preventBodyScroll.js"
import "../frontend/listing/addCartIcon.js"

import "../frontend/components/form.js"

import "../frontend/plugins/clipboard.js"
import "../frontend/plugins/colcade.js"
import "../frontend/plugins/time.js"

import "../frontend/footerPortals.js"

import "../packs/frontend/analytics.js"
import "../packs/frontend/blytics.js"
import "../packs/frontend/my/conversations.js"
import "../packs/frontend/sendFBEvent.js"

import "../controllers/"
// Alpine components
import.meta.glob("../frontend/alpine_components/**/*.js", { eager: true })

// Alpine
import "alpine-turbo-drive-adapter"
import Alpine from "alpinejs"
window.Alpine = Alpine
Alpine.start()

// ViewComponent
import.meta.glob("../../components/**/*component.scss", { eager: true })
import.meta.glob("../../components/**/*component.js", { eager: true })
